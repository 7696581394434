import React, { useState,useEffect } from 'react';
import leftArrow from './../../../../src/images/left-arrow.svg';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from '../../../Services/ApiService';
import { useSnackbar } from 'notistack';
const SunshineCentersAdd = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
      Name: '',
      Address:'',
      MobileNumber:'',
      Pincodes:'',
      Lat:'',
      Long:'',
        IsActive: 'Active',
      });
  const [loading, setLoading] = useState(true);
    
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = localStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        const updatedFormData = {
            ...formData,
            CreatedOn: getCurrentDateTime(),
            CreatedBy:userData.Id,
            Coordinates: formData.Lat+','+formData.Long,
            IsActive:formData.IsActive == "Active" ? true : false,
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.post("sdmsunshinescenter", updatedFormData);
            if (res) {
              if (res.Error == false) {
                handleCancel();
                enqueueSnackbar('Added Successfully!',{ variant: 'success', autoHideDuration: 3000 });
              } else {
                  enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
              }
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        // console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const handleKeyDown = (e) => {
        const keynum = e.which || e.keyCode;
      
        // Allow backspace, tab, and enter keys
        if (keynum === 8 || keynum === 9 || keynum === 13) {
          return;
        }
      
        // Allow only numeric characters and dot
        if (
          (keynum < 48 || keynum > 57) && // numeric characters
          (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
        ) {
          e.preventDefault();
        }
      };
      
  return (
    <div className='mainContainer'>
     <div class="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Create Sunshine Centers</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Name"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Address"
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="MobileNumber"
              name="MobileNumber"
              value={formData.MobileNumber}
              onChange={handleChange}
              required
              onKeyDown={handleKeyDown}
              inputProps={{ maxLength: 10 }}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
         
        </div>
        <div className='displayFlex m-b'>
        <div className="example-container">
            <TextField
              label="Pincodes"
              name="Pincodes"
              value={formData.Pincodes}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Latitude"
              name="Lat"
              value={formData.Lat}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Longitude"
              name="Long"
              value={formData.Long}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
        </div>
        <div className='displayFlex m-b'>
        <div className="example-container">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Save</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default SunshineCentersAdd;
