import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import ApiService from "../../../Services/ApiService";
const LanguageEdit = ({ onCancel, dataSource }) => {
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [LanguageName, setLanguageName] = useState("");
  const [shortName, setShortName] = useState("");

  const [isActive, setIsActive] = useState("Active");
  const [LanguageStatus, setLanguageStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();

  const [userDetails, setUserDetails] = useState(null);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ApprovedBy, setApprovedBy] = useState();
  const [ApprovedOn, setApprovedOn] = useState();
  const [IsPublished, setIsPublished] = useState(true);
  const [postedName, setPostedName]=useState()
  useEffect( () => {
    // getEditDetails(rowData);
    getData();
    setUserDetails(JSON.parse(localStorage.getItem("userDetail")));

  }, [dataSource]);
  const [editData, setEditData] = useState(); // Add this line
  useEffect(() => {
    // This effect runs whenever editData changes
    // console.log("Edit Data:", editData);
    getUserName()

  }, [editData]); // Add editData as dependency
  const getData = async () => {
    try {
      const res = await ApiService.getOne("sdmlanguage", dataSource);
      if (res) {
        setLanguageName(res.LanguageName);
        setShortName(res.LanguageShortName)
        setLanguageStatus(
          res.LanguageStatus == "Published" ? "approval" : res.LanguageStatus
        );
        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater((res.IsPublishLater == null || res.IsPublishedLater == false) ? false : true );
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setApprovedBy(res.ApprovedBy);
        setApprovedOn(res.ApprovedOn);
        setIsPublished(res.IsPublished);
        setEditData(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };



  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  const getUserName = async ()=>{
    try {
          const res = await ApiService.getOne("sdmusers", userDetails.Id);
          if (res) {
            // console.log(res);
            setPostedName(res.FirstName)
          }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
  }

  const handleApprovePublish = (e) => {
    e.preventDefault();
      onSubmit();
  };

  const onSubmit = async () => {
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const LanguageData = {
        LanguageName: LanguageName,
        LanguageShortName:shortName,
        LanguageStatus: LanguageStatus,
      IsPublishLater: isPublishLater,
      ScheduledPublishDt: formattedScheduledPublishDt,
      IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      IsPublished: IsPublished,
      ApprovedBy: ApprovedBy,
      ApprovedOn: ApprovedOn,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),
    };
    // console.log(LanguageData);
    try {
      const res = await ApiService.put("sdmlanguage", LanguageData);
      if (res) {
        // if(IsPublished){
          enqueueSnackbar("Updated Successfully!", { variant: "success", autoHideDuration: 3000 });
            onCancel();
        // }
      }else {
        enqueueSnackbar("An Error Occured!",{ variant: 'warning', autoHideDuration: 3000 });   
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    const formattedScheduledPublishDt = scheduledPublishDt
    ? new Date(scheduledPublishDt).toISOString()
    : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved,
      IsPublishedLater: isPublishLater,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
        const res = await ApiService.put("sdmlanguage/approve", publishData);
        // if (res) {
          enqueueSnackbar("Published Successfully!", {
            variant: "success", autoHideDuration: 3000
          });
          onCancel();
        // }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
    // console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setLanguageStatus(val);
    if(val != 'scheduled'){
        setScheduledPublishDt(null)
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true)
    } else {
      setIsApproved(false);
      setIsPublished(false)
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    onSubmit()
    setDialogOpen(false);
  };
  const handleDialogConfirm = async() => {
    setIsPublished(false)
    await onSubmit()
     onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false)
   await onSubmit()
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const formattedDate = formatDate(editData?editData.CreatedOn:'');
  const ModifiedformattedDate = formatDate(editData?editData.ModifiedOn:'-');
  return (
    <div>
      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Language</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <TextField
                  label="Language"
                  value={LanguageName}
                  onChange={(e) => setLanguageName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <TextField
                  label="Short Name"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {userDetails &&
                (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                  <div className="example-container">
                    <FormControl>
                      <InputLabel>Approval Status</InputLabel>
                      <Select
                        className="commonInput"
                        value={LanguageStatus}
                        onChange={(e) => onApprovalStatusChange(e.target.value)}
                        required
                      >
                        {ApproveStatus.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.viewValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <p className="imgInfo">[Select Approval to publish]</p>
                  </div>
                )}
            </div>
            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex">
                  <div className="example-container">
                    <TextField
                      className="commonInput"
                      label="Comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  {LanguageStatus === "scheduled" && (
                    <div className="example-container">
                      <TextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={scheduledPublishDt ? scheduledPublishDt.split("T")[0] : scheduledPublishDt}
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

<div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ''} By {editData ? editData.CreatedByName : ''}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ''} By {editData ? editData.ModifiedByName : '-'}
                </p>
              </div>
            </div>
          </div>
          <div className="submitButton">
          {LanguageStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {LanguageStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}
            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LanguageEdit;
