import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import SearchIcon from '@material-ui/icons/Search';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderStyles from '../../styles/Header';
import logos from '../../images/kindpng_1332517 2.svg'
import './../../../src/styles/common.css'; // Import common styles
import PersonIcon from '@material-ui/icons/Person';
import { withRouter } from 'react-router-dom'; // Import withRouter
class Header extends Component {
  state = {
    anchorEl: null,
    searchExpanded: false,
    userData: null // Initialize userData here
  };

  componentDidMount() {
    const storedData = localStorage.getItem('userDetail');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.setState({ userData: parsedData });
    }
  }

  handleSettingdToggle = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleSignOut = () => {
    localStorage.clear('userDetail');
    localStorage.clear('token');
    this.setState({ anchorEl: null });
    this.props.history.push('/signin'); // Redirect to signin page
  }

  handleSearchExpandToggle = () => {
    this.setState({ searchExpanded: !this.state.searchExpanded });
  };

  handleDrawerToggle = () => {
    this.props.toggleDrawer();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  }

  handleNotificationToggle = () => {
    this.props.toogleNotifications();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  }

  render() {
    const { classes, logo, logoAltText, toggleFullscreen } = this.props;
    const { anchorEl, searchExpanded } = this.state;

    return (
      <AppBar
        color='#EE3823'
        position="static"
        className={classes.appBar}>
        <Toolbar className="sb-disflex bg-shadow">
          <div className='dis-flex'>
            <IconButton
              className='menuIconNew'
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.branding}>
              <img src={logos} alt={logoAltText} className={classes.logo} />
            </div>
          </div>
          <div className='dis-flex'>
            <div className="userInfo">
              <p className="userName">{this.state.userData ? this.state.userData.FirstName : ''}</p>
              <p className="userRole">{this.state.userData ? this.state.userData.RoleName : ''}</p>
            </div>
            <IconButton
              aria-label="User Settings"
              aria-owns={anchorEl ? 'user-menu' : null}
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleSettingdToggle}
            >
              <PersonIcon className='color' />
            </IconButton>
          </div>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem onClick={this.handleCloseMenu}>
              <ListItemIcon>
                <AccountBoxIcon className='color' />
              </ListItemIcon>
              <ListItemText className='listItemTest' inset primary="User Profile" />
            </MenuItem>
            <MenuItem onClick={this.handleCloseMenu}>
              <ListItemIcon>
                <SettingsIcon className='color' />
              </ListItemIcon>
              <ListItemText className='listItemTest' inset primary="Settings" />
            </MenuItem>
            <MenuItem onClick={this.handleSignOut}>
              <ListItemIcon>
                <ExitToAppIcon className='color' />
              </ListItemIcon>
              <ListItemText className='listItemTest' inset primary="Sign out" />
            </MenuItem>
          </Menu>
        </Toolbar>
        <Hidden smUp>
          <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
            <Toolbar className={classes.toolBar}>
              <div className={classes.searchWrapper}>
                <form className={classNames(classes.searchForm, 'mr-0')}>
                  <IconButton
                    aria-label="Search"
                    className={classes.searchIcon}
                  >
                    <SearchIcon />
                  </IconButton>
                  <input className={classes.searchInput} type="text" placeholder="Search" autoFocus="true" />
                </form>
              </div>
            </Toolbar>
          </Collapse>
        </Hidden>
      </AppBar>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  logoAltText: PropTypes.string
};

export default withStyles(HeaderStyles)(withRouter(Header)); // Wrap with withRouter