import React, { useState, useEffect } from "react";
import leftArrow from "./../../../src/images/left-arrow.svg";
import './../../../src/styles/common.css'; // Import common styles
import { DialogBox } from "../Dialog Box";
import ApiService from "../../Services/ApiService";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { Loader } from "../Loader";
const Priority = ({ onCancel, name,listApiUrl,tableName, dataSource,listApi,priorityApiUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
    const [dataSources, setDataSources] = useState([]);
    const [oldPriority, setOldPriority] = useState(null);
    // const [newPriority, setNewPriority] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(); 
  const [loading, setLoading] = useState(false);
  const [droppedItem, setDroppedItem] = useState(null); // State for droppedItem
  const [index, setIndex] = useState(0);
  const itemsPerPage = 20; 
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    fetchData()
  }, [index]);
  const fetchData = async () => {
    let filterData ={
      Index: index,
      Limit: itemsPerPage,
      OrderByTable: tableName,
      OrderByField: 'Priority',
      Sort: '',
      Aggregator: 'OR',
      Conditions: [
        {
          TableName: tableName,
          FieldName: 'IsActive',
          Operator: 'equal',
          FieldValue: 1
        },
      ]
    }
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.post(listApiUrl, filterData);
      if (response && response[tableName]) {
        setDataSources(prevDataSources => {
          // Merge the previous data with the new data
          const newData = response[tableName];
          return [...prevDataSources, ...newData];
        });
        setTotalCount(response.count)
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const [draggedItem, setDraggedItem] = useState(null);
  const [yourData, setYourData] = useState(
    dataSources
  );

  const handleDragStart = (event, item) => {
    // console.log(event,item);
     const oldNo = item.Priority;
    setOldPriority(oldNo);
    setDraggedItem(item);
    event.dataTransfer.setData('text/plain', ''); // Required for Firefox
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, droppedItem) => {
    event.preventDefault();
    setDroppedItem(droppedItem)
    handleDialogOpen()
    // // console.log(oldNo)
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogData(false)

    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {

const oldIndex = dataSources.findIndex((item) => item === draggedItem);
    const newIndex = dataSources.findIndex((item) => item === droppedItem);
    // console.log('Old position:',draggedItem, oldIndex );
    // console.log('New position:', newIndex + 1);
    const newData = [...dataSources];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, draggedItem);
    setDataSources(newData);
    setDialogData(true)
    // updateToggleState()

    const req = {
      "OldPriority": draggedItem.Priority,  
      "NewPriority": droppedItem.Priority
    }
    try {
      const res = await ApiService.put(priorityApiUrl, req);
      if (res) {
    handleDialogClose(); 
    setDataSources([]);
      setIndex(0);
    // fetchData();
    enqueueSnackbar('Updated Successfully!',{ variant: 'success', autoHideDuration: 3000 });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }

  };
  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const threshold = 0.8; // Threshold value to account for rounding issues
    // Check if the user has scrolled near the bottom
    if ((scrollHeight !=0) && (scrollHeight - scrollTop <= clientHeight + threshold) && (dataSources.length<totalCount)) {
      setIndex(prevIndex => prevIndex + itemsPerPage);
    }
  };
  return (
<div>
{loading && <Loader />}
    <DialogBox
     title={"Confirmation"}
     content={"Are you sure want to re-order the priority?"}
     open={dialogOpen}
     onClose={handleDialogClose}
     onConfirm={handleDialogConfirm}
   />
<div className="mainContainer">
   <div className="dis-flex headTitle">
     <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
     <p>{name} Priority List</p>
   </div>
   <div className="priorityContainer">
   <div className="priorityHeader" >
     <div className="priorityTitle">Priority</div>
     <div className="priorityTitle">{name}</div>
   </div>
   <div>
     <div className="priorityContent">
     <div onDragOver={handleDragOver} onScroll={handleScroll} className="w-100 priorityTable-inner">
   {dataSources && dataSources.map((item, index) => (
     <div
     className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
       key={index}
       draggable
       onDragStart={(e) => handleDragStart(e, item)}
       onDrop={(e) => handleDrop(e, item)}
     >
       <div className="priorityTitle colorText">{item.Priority}</div>
       <div >{item.PriorityName}</div>
     </div>
   ))}
 </div>
     </div>
 
   </div>
   </div>
  
 </div>
 </div>
   
  );
};

export default Priority;