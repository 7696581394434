import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";
import environment from "../../../Environment/environment";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import only the necessary Quill styles
import ApiService from "../../../Services/ApiService";
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Loader } from '../../Loader';
const ProductEdit = ({ onCancel, dataSource }) => {
  const { BASE_URL } = environment;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const formRef = useRef(null);
  // const [genreName, setGenreName] = useState("");
  const [Packagevalue, setPackageValue] = React.useState([]);
  const [PackageArray, setPackage] = useState([]);
  const [ProductName, setProductName] = useState("");
  const [Cost, setCost] = useState(0);
  const [PromotionalCost, setPromotionalCost] = useState(0);
  const [IsNewUser, setIsNewUser] = useState(false);
  const [IsExistingUser, setIsExistingUser] = useState(false);
  const [SavingCost, setSavingCost] = useState(0);
  const [ImageUrl, setImageUrl] = useState("");
  const [PromotionalSpace1, setPromotionalSpace1] = useState();
  const [PromotionalSpace2, setPromotionalSpace2] = useState();
  const [Priority, setPriority] = useState("");
  const [PriorityName, setPriorityName] = useState("");
  const [ApprovedBy, setApprovedBy] = useState();
  const [ApprovedOn, setApprovedOn] = useState();
  const [IsPublished, setIsPublished] = useState(true);
  const [productWithPackages, setproductWithPackages] = useState("");
  const [isActive, setIsActive] = useState("Active");
  const [ProductStatus, setProductStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();
  const [selectedPackages, setSelectedPackages] = useState();

  const [userDetails, setUserDetails] = useState(null);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [isPublished, setIsPublised] = useState(true);
  const [postedName, setPostedName] = useState();
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    // getEditDetails(rowData);
    getPackageList();
    getData();
    setUserDetails(JSON.parse(localStorage.getItem("userDetail")));
    // getUserName()
  }, [dataSource]);
  useEffect(() => {
    if (userDetails && userDetails.Id) {
      getUserName();
    }
  }, [userDetails]);
  const [editData, setEditData] = useState(); // Add this line
  useEffect(() => {
    // This effect runs whenever editData changes
    // console.log("Edit Data:", editData);
    // getUserName()
  }, [editData, userDetails]); // Add editData as dependency
  const getPackageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    setLoading(true)
    try {
      const res = await ApiService.post("sdmpackage/list/v1", typeRequest);
      if (res) {
        setPackage(res);
    setLoading(false)
      }
    } catch (error) {
    setLoading(false)
      console.error("Error updating toggle state:", error);
    }
  };
  const getData = async () => {
    try {
      const res = await ApiService.getOne("sdmproduct", dataSource);
      if (res) {
        setProductName(res.ProductName);
        setCost(res.Cost);
        setPromotionalCost(res.PromotionalCost);
        setIsNewUser(res.IsNewUser);
        setIsExistingUser(res.IsExistingUser);
        setSavingCost(res.SavingCost);
        setImageUrl(res.ImageUrl);
        // setFile(res.ImageUrl)
        setPromotionalSpace1(
          res.PromotionalSpace1 ? res.PromotionalSpace1 : ""
        );
        setPromotionalSpace2(
          res.PromotionalSpace2 ? res.PromotionalSpace2 : ""
        );
        setPriority(res.Priority);
        setPriorityName(res.PriorityName);
        setApprovedBy(res.ApprovedBy);
        setApprovedOn(res.ApprovedOn);
        setIsPublished(res.IsPublished);
        setproductWithPackages(res.productWithPackages);
        setProductStatus(
          res.ProductStatus == "Published" ? "approval" : res.ProductStatus
        );
        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater(res.IsPublishLater);
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setSelectedPackages(res.productWithPackages);
        setEditData(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Allow only numeric characters and dot
    if (
      (keynum < 48 || keynum > 57) && // numeric characters
      (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
    ) {
      e.preventDefault();
    }
  };
  useEffect(() => {}, [Packagevalue]);
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  const getUserName = async () => {
    try {
      if (userDetails.Id) {
        // Check if userDetails and Id property exist
        const res = await ApiService.getOne("sdmusers", userDetails.Id);
        if (res) {
          // console.log(res);
          setPostedName(res.FirstName);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleApprovePublish = (e) => {
    e.preventDefault();
    if (userDetails.RoleId == 1 || userDetails.RoleId == 3) {
      setDialogOpen(true);
    } else {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (!IsNewUser && !IsExistingUser) {
      setcheckboxVali(true);
      return; // Prevent form submission
    }
    
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const ProductData = {
      ProductName: ProductName,
      Cost: Cost,
      PromotionalCost: PromotionalCost,
      SavingCost: SavingCost,
      ImageUrl: ImageUrl,
      IsNewUser: IsNewUser,
      IsExistingUser: IsExistingUser,
      PromotionalSpace1: PromotionalSpace1,
      PromotionalSpace2: PromotionalSpace2,
      Priority: Priority,
      PriorityName: PriorityName,
      IsPublished: IsPublished,
      ApprovedBy: ApprovedBy,
      ApprovedOn: ApprovedOn,
      productWithPackages: selectedPackages.map((selectedPackage) => ({
        ProductId:editData.Id,
        PackageId: selectedPackage.PackageId ? selectedPackage.PackageId : selectedPackage.Id,
        PackageName: selectedPackage.PackageName
      })),
      ProductStatus: ProductStatus,
      IsPublishLater: isPublishLater,
      ScheduledPublishDt: formattedScheduledPublishDt,
      IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: isActive == "Active" ? true : false,
    };
    // console.log(ProductData);
    try {
      const res = await ApiService.put("sdmproduct", ProductData);
        if (res) {
          // const uploadedImages = {
          //   'Id': editData.Id,
          //   'FilePath':file
          // }
          const formData = new FormData();
          formData.append('FilePath', file?.name);
          formData.append('File', file); 
          formData.append('Id', editData.Id);
          try{
            if(file){
              const imgApi = await ApiService.put('sdmproduct/uploadimage',formData) 
            }
          // if(imgApi){
            // if (isPublished) {
            if(res){
             enqueueSnackbar("Updated Successfully!", { variant: "success", autoHideDuration: 3000 });
              onCancel();
            }else{
              enqueueSnackbar("An Error Occured!",{ variant: 'warning', autoHideDuration: 3000 });
            }
            // }
          // }
          } catch (error){
        console.error("Error updating toggle state:", error);
          }
        }
      
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    onSubmit()
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved,
      IsPublishedLater: isPublishLater,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
      const res = await ApiService.put("sdmproduct/approve", publishData);
      // if (res) {
        enqueueSnackbar("Published Successfully!", {
          variant: "success", autoHideDuration: 3000
        });
      onCancel();

      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    // console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setProductStatus(val);
    if (val != "scheduled") {
      setScheduledPublishDt(null);
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true);
    } else {
      setIsApproved(false);
      setIsPublished(false)
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    // onSubmit();
    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const formattedDate = formatDate(editData ? editData.CreatedOn : "");
  const ModifiedformattedDate = formatDate(editData?editData.ModifiedOn:'-');

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError("Image size should not exceed 2MB.");
        setFile(null); // Clear the file state
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setError("Only JPG, JPEG, PNG, BMP, and GIF formats are supported.");
        setFile(null); // Clear the file state
      } else {
        setFile(selectedFile);

        setImageUrl(selectedFile.name),
          // // console.log(formData.LogoUrl)
          setError(null); // Clear any existing error
        // onFileSelected(selectedFile);
      }
    }
  };
  useEffect(() => {}, [ImageUrl]);
  const clearFile = () => {
    setFile(null);
    setImageUrl(null);
    setError(null);
  };
  const clearFileEdit = () => {
    setImageUrl(null);
    setError(null);
  };
  const handleChange1 = (content) => {
    // const { value } = e.target;

    setPromotionalSpace1(content);
  };

  return (
    <div>
            {loading && <Loader />}
      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Product</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Name"
                  name="ProductName"
                  value={ProductName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Cost"
                  name="Cost"
                  value={Cost}
                  onChange={(e) => setCost(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                  inputProps={{ maxLength: 4 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              {/* <div className="example-container">
                <MaterialUITextField
                  label="Promotional Cost"
                  name="PromotionalCost"
                  value={PromotionalCost}
                  onChange={(e) => setPromotionalCost(e.target.value)}
                  onKeyDown={handleKeyDown}
                  inputProps={{ maxLength: 4 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports numbers upto 50 chars.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div> */}
            </div>
            <div className="displayFlex">
              <div className="example-container">
                <MaterialUITextField
                  label="Saving Cost"
                  name="SavingCost"
                  value={SavingCost}
                  onChange={(e) => setSavingCost(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>

              <div className="exmple-container p-21">
                <div className="commonInput">
                  <p className="post">User Type</p>
                  <Checkbox
                    checked={IsNewUser}
                    name="IsNewUser"
                    onChange={(e) => setIsNewUser(e.target.value)}
                    sx={{
                      color: "#EE3823",
                      "&.Mui-checked": {
                        color: "#EE3823",
                      },
                    }}
                  />{" "}
                  New
                  <Checkbox
                    checked={IsExistingUser}
                    name="IsExistingUser"
                    onChange={(e) => setIsExistingUser(e.target.value)}
                    sx={{
                      color: "#EE3823",
                      "&.Mui-checked": {
                        color: "#EE3823",
                      },
                    }}
                  />{" "}
                  Existing
                  {/* Validation error message */}
                  {checkboxVali && !IsExistingUser && !IsNewUser && (
                    <FormHelperText error>
                      Please select at least one option.
                    </FormHelperText>
                  )}
                </div>
              </div>

              <div className="example-container p-b-29">
                <div className="checkbox-lable m-24">
                  Logo
                  <span className="cursor imgInfoIcon">
                    <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                      <InfoIcon className="Icon-style svg-info " />
                    </Tooltip>
                  </span>
                </div>
                <div className="disflex">
                  <div>
                    <label htmlFor="fileInput" className="upload-file-label">
                      Choose Files
                    </label>
                    <input
                      className="upload-file"
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                  <span>
                    {file ? (
                      <a onclick="event.preventDefault();" className="uploaded-file-link">
                        {file?.name}
                      </a>
                    ) : (
                      <a href={BASE_URL+'/product/'+ImageUrl} target="_blank" className="uploaded-file-link">
                        {ImageUrl}
                      </a>
                    )}
                    {/* {file && <button onClick={clearFile} >&times;</button>} */}
                    {file ? (
                    
                        <CloseIcon  className="closeUpload cursor"
                        onClick={clearFile} />
                    ) : ImageUrl ? (
                    
                        <CloseIcon  className="closeUpload cursor"
                        onClick={clearFileEdit} />
                    ) : null}
                  </span>
                </div>
                {error && <div className="imgError">{error}</div>}
                <p className="imgInfo">
                  [Recommended for this image pixel width(200) x height(150)]
                </p>
              </div>
            </div>

            {PackageArray.SdMPackage && PackageArray.SdMPackage.length > 0 && (
  <div className="exmple-container p-45">
    <div className="commonInput">
    <Autocomplete
  multiple
  id="autocomplete-packages"
  value={selectedPackages || []}
  onChange={(event, value) => setSelectedPackages(value)}
  options={PackageArray.SdMPackage.filter(
    (option) =>
      !(selectedPackages &&
        selectedPackages.find(
          (selected) => selected.PackageId === option.Id
        )) &&
      !(selectedPackages || []).some(
        (selected) => selected.PackageId === option.Id
      )
  )}
  getOptionLabel={(option) => option.PackageName}
  getOptionSelected={(option, value) => option.PackageId === value.PackageId} // add this line
  style={{ width: 858, marginBottom: 24 }}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        key={index}
        label={option.PackageName}
        {...getTagProps({ index })}
        className="chipsStyle"
      />
    ))
  }
  renderInput={(params) => (
    <MuiTextField {...params} label="Packages" variant="standard" />
  )}
/>
    </div>
  </div>
)}
            <div className="example-container m-b">
              {/* {PromotionalSpace1 ? ( */}
              <p className="post mb10">Promotional Space1</p>
              <ReactQuill
                theme="snow"
                className="quill-editor multiSelectWidth"
                value={PromotionalSpace1 ? PromotionalSpace1 : " "}
                onChange={handleChange1}
                modules={{
                  toolbar: toolbarOptions, // Pass the custom toolbar options
                }}
                placeholder={"Write something"}
              />
              {/* // ):null} */}
            </div>
            <div className="example-container m-b">
              {/* {PromotionalSpace2 ? ( */}
              <p className="post mb10">Promotional Space2</p>
              <ReactQuill
                theme="snow"
                className="quill-editor multiSelectWidth"
                value={PromotionalSpace2 ? PromotionalSpace2 : " "}
                onChange={handleChange1}
                modules={{
                  toolbar: toolbarOptions, // Pass the custom toolbar options
                }}
                placeholder={"Write something"}
              />
              {/* // ):null} */}
            </div>

            <div className="displayFlex m-b">
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {userDetails &&
                (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                  <div className="displayFlex">
                    <div className="example-container">
                      <FormControl>
                        <InputLabel>Approval Status</InputLabel>
                        <Select
                          className="commonInput"
                          value={ProductStatus}
                          onChange={(e) =>
                            onApprovalStatusChange(e.target.value)
                          }
                          required
                        >
                          {ApproveStatus.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.viewValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="example-container">
                      <MaterialUITextField
                        className="commonInput"
                        label="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </div>
                )}
            </div>

            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex">
                  {ProductStatus === "scheduled" && (
                    <div className="example-container">
                      <MaterialUITextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={
                          scheduledPublishDt
                            ? scheduledPublishDt.split("T")[0]
                            : scheduledPublishDt
                        }
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

<div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ''} By {editData ? editData.CreatedByName : ''}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ''} By {editData ? editData.ModifiedByName : '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="submitButton">
          {ProductStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {ProductStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}
            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductEdit;
