import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { TextField as MuiTextField } from '@mui/material/TextField';
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const NetworkAdd = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    NetworkName: "",
    IsActive: "Active",
  });
  const [ListArray, setListArray] = useState([]);
  useEffect(() => {
    getGenreList();
    getChannelList();
    const storedData = localStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const getGenreList = async () => {
    try {
      const res = await ApiService.getAll("sdmnetwork/genrewithchannels");
      if (res) {
        // console.log(res);
        setListArray(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/channellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, [userData]);
  
  const [value, setValue] = useState([]);

  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const[NetworkAndGenre, setListNetworkGenre]=useState([])
  const [ChannelArray, setChannel] = useState([]);
  const [Channelvalue, setChannelValue] = React.useState([]);


  const handleChanges = (genreId, selectedChannels,genreName) => {
    setSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]: selectedChannels,
    }));
    setSelectedGenre((prevState)=>({
      ...prevState,
      [genreId]: genreName,
    }))
    // console.log(selectedChannels)
  };

  const [loading, setLoading] = useState(true);

  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    const transformedArray = Channelvalue.reduce((acc, curr) => {
      // Check if the genre exists in the accumulator array
      const existingGenre = acc.find(item => item.GenreId === curr.GenreId);
      if (existingGenre) {
        // If the genre exists, add the channel to its NetworkGenreChannel array
        existingGenre.NetworkGenreChannel.push({
          ChannelId: curr.ChannelId,
          ChannelName: curr.ChannelName
        });
      } else {
        // If the genre doesn't exist, create a new entry for it in the accumulator array
        acc.push({
          GenreId: curr.GenreId,
          GenreName: curr.GenreName,
          NetworkGenreChannel: [
            {
              ChannelId: curr.ChannelId,
              ChannelName: curr.ChannelName
            }
          ]
        });
      }
      return acc;
    }, []);


    const updatedFormData = {
      ...formData,
      CreatedOn: getCurrentDateTime(),
      CreatedBy: userData.Id,
      IsActive: formData.IsActive == "Active" ? true : false,
      NetworkStatus :'pendingReview',
      NetworkGenres:transformedArray
    };
    setFormData(updatedFormData);
    try {
      const res = await ApiService.post("sdmnetwork", updatedFormData);
      if (res) {
        if (res.Error == false) {
          enqueueSnackbar('Added Successfully!',{ variant: 'success', autoHideDuration: 3000 });
          handleCancel();
        } else {
          enqueueSnackbar(res.Message,{ variant: 'warning', autoHideDuration: 3000 });   
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }

  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  return (
    <div className="mainContainer">
      <div class="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Create Network</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
        <div className="Content">
          <div className="displayFlex">
            <div className="example-container">
              <MaterialUITextField
                label="Network Name"
                name="NetworkName"
                value={formData.NetworkName}
                onChange={handleChange}
                required
                 inputProps={{ maxLength: 50 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container m-b">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {/* <div className="exmple-container m-b">
          <div className="commonInput m-24">
          <p className="post">Genre</p>
          {ListArray
              ? ListArray.map((genre) => (
                  <Autocomplete
                    key={genre.Id}
                    multiple
                    id={`autocomplete-${genre.Id}`}
                    value={selectedChannels[genre.Id] || []}
                    onChange={(event, value) => handleChanges(genre.Id,value,genre.GenreName)}
                    options={genre.channelList}
                    getOptionLabel={(option) => option.ChannelName}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.ChannelName}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    
                    style={{ width: 858,marginBottom:24 }}
                    renderInput={(params) => (
                      <MuiTextField {...params} label={genre.GenreName} 
                      variant='standard'
                      />
                    )}
                  />
                ))
              : null}
          </div>
          </div> */}
          {ChannelArray &&
            ChannelArray.length > 0 && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={Channelvalue}
                    onChange={(event, newValue) => {
                      setChannelValue(newValue);
                    }}
                    options={ChannelArray.filter(
                      (option) =>
                        !value.some((selected) => selected.Id === option.Id)
                    )}
                    getOptionLabel={(option) => (option.ChannelName+"("+option.GenreName+")")}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={(option.ChannelName+"("+option.GenreName+")")}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={"Channels (" + Channelvalue.length +")"}
                        placeholder="Channels"
                      variant='standard'
                      />
                    )}
                  />
                </div>
              </div>
            )}
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Save
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NetworkAdd;
